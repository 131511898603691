.event-banner_wrapper {
  margin: 60px 0 0;
  padding: 44px 40px;
  border-radius: 24px;
  // background: linear-gradient(226deg, $purple-2-200, $purple-2-100);
  background: $purple-2-200;
  column-gap: 24px;
  @media screen and (max-width: 991px) {
    row-gap: 20px;
    margin: 50px 0 0;
    padding: 36px 32px;
    border-radius: 20px;
  }
  @media screen and (max-width: 767px) {
    margin: 40px 0 0;
    padding: 28px 24px;
    border-radius: 16px;
  }
  @media screen and (max-width: 575px) {
    margin: 20px 0 0;
  }
  h4 {
    @media screen and (max-width: 575px) {
      font-size: 22px !important;
      line-height: 28px !important;
    }
  }
  h2 {
    // font-family:"modern-love" !important;
    font-family: '"elza", sans-serif !important';
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 16px;
    @media screen and (max-width: 991px) {
      font-size: 40px;
      line-height: 52px;
      margin-bottom: 12px;
    }
    @media screen and (max-width: 767px) {
      font-size: 32px !important;
      line-height: 44px !important;
    }
    @media screen and (max-width: 575px) {
      font-size: 24px !important;
      line-height: 32px !important;
    }
  }
  p {
    font-size: 24px;
    line-height: 29px;
    font-weight: 400;
    margin-bottom: 8px;
    @media screen and (max-width: 991px) {
      font-size: 20px;
      line-height: 25px;
    }
    @media screen and (max-width: 767px) {
      font-size: 14px !important;
      line-height: 17px !important;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .btn {
    min-width: 168px;
  }
  .scrore-details_right {
    row-gap: 16px;
    @media screen and (max-width: 991px) {
      row-gap: 12px;
    }
    @media screen and (max-width: 767px) {
      row-gap: 8px;
    }
    .MuiRating-icon {
      color: $white;
      &.MuiRating-iconFilled {
        color: $dark-purple-900;
      }
    }
    p {
      font-size: 48px;
      line-height: 54px;
      font-weight: 500;
      color: $dark-purple-900;
      padding-top: 4px;
      @media screen and (max-width: 1399px) {
        font-size: 44px;
        line-height: 50px;
      }
      @media screen and (max-width: 991px) {
        font-size: 40px;
        line-height: 46px;
      }
      @media screen and (max-width: 767px) {
        font-size: 32px;
        line-height: 38px;
      }
    }
  }
}

.hero-banner_wrapper {
  margin: 60px 0 0;
  padding: 34px 30px;
  border-radius: 24px;
  // background: linear-gradient(226deg, $purple-2-200, $purple-2-100);
  background: $purple-5-100;
  column-gap: 14px;
  @media screen and (max-width: 991px) {
    row-gap: 10px;
    margin: 40px 0 0;
    padding: 26px 22px;
    border-radius: 20px;
  }
  @media screen and (max-width: 767px) {
    margin: 40px 0 0;
    padding: 18px 14px;
    border-radius: 16px;
  }
  @media screen and (max-width: 575px) {
    margin: 20px 0 0;
  }

  h2 {
    // font-family:"modern-love" !important;
    font-family: '"elza", sans-serif !important';
    font-weight: 700;
    font-size: 35px;
    line-height: 28px;
    @media screen and (max-width: 991px) {
      font-size: 35px;
      line-height: 28px;
    }
    @media screen and (max-width: 767px) {
      font-size: 25px !important;
      line-height: 20px !important;
    }
    @media screen and (max-width: 575px) {
      font-size: 25px !important;
      line-height: 20px !important;
    }
  }
  .btn {
    padding: 15px 20px;
    min-width: 168px;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
  }
  .dynamic-text {
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
  }
}

#theme-banner {
  .event-banner_wrapper {
    background-color: $purple-4;
    @media screen and (max-width: 575px) {
      flex-wrap: wrap;
    }
    .inner-wrapper {
      @media screen and (max-width: 992px) {
        flex-wrap: wrap;
      }
    }
    .theme-image-box {
      @media screen and (max-width: 1199px) {
        margin-left: 0;
      }
    }
    h2 {
      @media screen and (max-width: 1199px) {
        font-size: 38px;
      }
      @media screen and (max-width: 992px) {
        font-size: 34px;
        line-height: 44px;
      }
    }
    h4 {
      @media screen and (max-width: 1199px) {
        font-size: 28px;
      }
      @media screen and (max-width: 992px) {
        font-size: 28px;
        line-height: 38px;
      }
    }
    // p {
    //   @media screen and (max-width: 992px) {
    //     font-size: 22px;
    //     line-height: 28px;
    //   }
    // }
  }
  .theme-image {
    border-radius: 24px;
    margin-bottom: -80px;
    object-fit: cover;
    @media screen and (max-width: 1199px) {
      margin-bottom: 0;
    }
    @media screen and (max-width: 992px) {
      margin-bottom: 10px;
    }
  }
}

.category-pills {
  ul {
    padding: 0;
    display: flex;
    align-items: center;
    column-gap: 12px;
    li {
      padding: 12px 16px;
      height: 42px;
      background-color: transparentize($gray-100, 0.6);
      border: 1px solid $gray-900;
      border-radius: 8px;
      font-size: 14px;
      line-height: 19px;
      font-weight: 500;
      color: $gray-900;
      column-gap: 12px;
      button {
        padding: 0;
        color: $dark-purple;
      }
    }
  }
}

.event-list-row {
  row-gap: 24px;
}

.theme-wise-event-section {
  .event-banner_wrapper {
    h2 {
      max-width: 1000px;
    }
  }
}
